import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core'

export type StrokeColor = 'gradient-blue' | 'gradient-orange' | 'gradient-cyan' | 'gradient-red'

@Component({
  selector: 'app-rounded-percentage',
  standalone: true,
  imports: [],
  templateUrl: './rounded-percentage.component.html',
  styleUrl: './rounded-percentage.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundedPercentageComponent {
  size = input.required<number>()
  percentage = input.required<number>()
  strokeColor = input.required<StrokeColor>()
  strokeWidth = input<number>(9)

  radius = computed(() => {
    return this.size() / 2 - this.strokeWidth() / 2
  })

  circumference = computed(() => {
    return 2 * Math.PI * this.radius()
  })

  dashoffset = computed(() => {
    return this.circumference() - (this.circumference() * this.percentage()) / 100
  })

  shadow = computed(() => {
    const colors = {
      'gradient-blue': '#385cad',
      'gradient-orange': '#ff9015',
      'gradient-cyan': '#65BAAF',
      'gradient-red': '#ff595a'
    }

    const color = colors[this.strokeColor()]
    return `drop-shadow(0px 0px 3px ${color})`
  })
}
