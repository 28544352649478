<div class="bg-theme-darkBlue p-4 rounded-2xl text-center flex flex-col gap-2 w-full text-sm">
  <h3>{{ name() }}</h3>
  <app-rounded-percentage
    class="m-auto"
    [size]="120"
    [percentage]="percentage() * 100"
    [strokeColor]="color()"
    [strokeWidth]="9">
    <div>
      <p>llevas</p>
      <p class="font-semibold text-lg">{{ progress() | number: decimalsFormat() }}</p>
      <p>{{ label() }}</p>
    </div>
  </app-rounded-percentage>

  <div class="flex flex-col bg-theme-red rounded-xl shadow-inset-custom-2 text-theme-darkBlue p-2">
    <p>Meta</p>
    <strong>{{ goal() | number: decimalsFormat() }} {{ label() }}</strong>
  </div>
</div>
