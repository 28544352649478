import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core'
import { RoundedPercentageComponent, StrokeColor } from '../rounded-percentage/rounded-percentage.component'

import { DecimalPipe } from '@angular/common'

@Component({
  selector: 'app-progress-card',
  standalone: true,
  imports: [RoundedPercentageComponent, DecimalPipe],
  templateUrl: './progress-card.component.html',
  styleUrl: './progress-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressCardComponent {
  name = input.required<string>()
  goal = input.required<number>()
  progress = input.required<number>()
  percentage = input.required<number>()

  label = input.required<string>() // sku's, hectos, cartones, %
  colorScheme = input.required<string>()
  decimals = input.required<number>()

  decimalsFormat = computed(() => `1.${this.decimals()}-${this.decimals()}`)
  color = computed(() => `gradient-${this.colorScheme()}` as StrokeColor)
}
