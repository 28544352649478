@let width = size() + 10 + 'px';
@let height = size() + 10 + 'px';
@let cx = (size() + 10) / 2;
@let cy = (size() + 10) / 2;
@let stroke = 'url(#' + strokeColor() + ')';

<div class="relative w-fit">
  <svg
    class="-rotate-90"
    [style]="{ width, height }">
    <circle
      class="w-full h-full fill-none stroke-theme-mediumBlue1"
      [attr.cx]="cx"
      [attr.cy]="cy"
      [attr.r]="radius()"
      [attr.stroke-width]="strokeWidth() / 3"></circle>
    <circle
      [style]="{ filter: shadow() }"
      class="w-full h-full fill-none"
      [attr.stroke]="stroke"
      [attr.cx]="cx"
      [attr.cy]="cy"
      [attr.r]="radius()"
      [attr.stroke-width]="strokeWidth()"
      [attr.stroke-dasharray]="circumference()"
      [attr.stroke-dashoffset]="dashoffset()"></circle>

    <defs>
      <linearGradient id="gradient-blue">
        <stop
          offset="0%"
          stop-opacity="1"
          stop-color="#385cad" />
        <stop
          offset="100%"
          stop-opacity="1"
          stop-color="#7894d2" />
      </linearGradient>
      <linearGradient id="gradient-orange">
        <stop
          offset="0%"
          stop-opacity="1"
          stop-color="#ff9015" />
        <stop
          offset="100%"
          stop-opacity="1"
          stop-color="#ffc600" />
      </linearGradient>
      <linearGradient id="gradient-cyan">
        <stop
          offset="0%"
          stop-opacity="1"
          stop-color="#65BAAF" />
        <stop
          offset="100%"
          stop-opacity="1"
          stop-color="#b1fdf3" />
      </linearGradient>
      <linearGradient id="gradient-red">
        <stop
          offset="0%"
          stop-opacity="1"
          stop-color="#ff595a" />
        <stop
          offset="100%"
          stop-opacity="1"
          stop-color="#ff8485" />
      </linearGradient>
    </defs>
  </svg>
  <div class="absolute inset-0 m-auto grid place-items-center">
    <ng-content></ng-content>
  </div>
</div>
